<template>
    <settings-wrapper>
        <template v-slot:child>
            <div class="box-border w-60-l w-90 center mt4">
                <div>
                    <div class="box-border-bottom pa3 b f4" style="background: white">Change Password</div>
                    <form class="pa3" style="background: #fbfcfe" @submit.prevent="handleSubmit">
                        <div class="flex flex-column">
                            <div class="b pt4 pb2">Old Password</div>
                            <input
                                type="password"
                                class="pa2 box-border"
                                name=""
                                placeholder="Old password"
                                id="old-password"
                                v-model="state.oldPassword"
                            />
                        </div>
                        <div class="flex flex-column">
                            <div class="b pt3 pb2">New Password</div>
                            <input
                                type="password"
                                class="pa2 box-border"
                                name=""
                                placeholder="New password"
                                id="new-password"
                                v-model="state.newPassword"
                            />
                        </div>
                        <div class="flex flex-column">
                            <div class="b pt3 pb2">Confirm New Password</div>
                            <input
                                type="password"
                                class="pa2 box-border"
                                name=""
                                placeholder="Confirm new password"
                                id="confirm-new-password"
                                v-model="state.confirmNewPassword"
                            />
                            <span v-if="state.newPassword !== '' && state.confirmNewPassword !== '' && (state.newPassword !== state.confirmNewPassword)" class="text-danger">New password does not match</span>
                        </div>
                        <div class="pa4 mt3" style="border-top: 1px solid #e3e8ee">
                            <div class="flex justify-between">
                                <div></div>
                                <div class="flex">
                                    <button type="submit" class="ml3" :disabled="state.isProcesseing || state.confirmNewPassword === '' || (state.newPassword !== state.confirmNewPassword)">
                                        {{ state?.isProcesseing ? 'Processing...' : 'Submit' }}
                                    </button>
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </template>
    </settings-wrapper>
</template>

<script>
import { computed, onMounted, reactive, ref } from 'vue'
import { useStore } from 'vuex'
import SettingsWrapper from '@/views/Settings/widgets/SettingsWrapper'
import OrgData from '@/components/OrgData'
import ToggleSwitch from '@/components/ToggleSwitch'

export default {
    name: 'AccountSettings',
    components: { SettingsWrapper, OrgData, ToggleSwitch },

    setup() {
        const store = useStore()

        const initialState = () => ({
            oldPassword: '',
            newPassword: '',
            confirmNewPassword: '',
            isProcesseing: false,
        })
        const state = reactive(initialState())

        const handleSubmit = () => {
            state.isProcesseing = false
            store.dispatch('Settings/changePassword', {
                oldPassword: state.oldPassword,
                newPassword: state.newPassword
            }).finally(() => {
                state.oldPassword = ''
                state.newPassword = ''
                state.confirmNewPassword = ''
                state.isProcesseing = false
            })
        }

        onMounted(() => {})

        return {
            state,
            handleSubmit
        }
    }
}
</script>

<style scoped>
#button-update {
    position: relative;
    padding: 15px 50px;
    margin: 20px;
    /* display: flex; */
    /* justify-content: flex-end; */
}
</style>
